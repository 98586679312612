<div class="container">
  <img [src]="firstAdvertData.srcImage">
  <div class="text-container">
    <p class="description">{{ firstAdvertData.description }}</p>
    <p class="sub-title">{{ firstAdvertData.firstSubTitle }}</p>
    <p class="paragraph">{{ firstAdvertData.firstParagraph }}</p>
    <p class="sub-title">{{ firstAdvertData.secondSubTitle }}</p>
    <p class="paragraph">{{ firstAdvertData.secondParagraph }}</p>
  </div>
</div>
