import { Component, Input } from '@angular/core';
import { IPractitioner } from '../hero-prac/practitioner.interface';
import { ServiceDataService } from '../data-service.service';

@Component({
  selector: 'app-show-practitioners',
  templateUrl: './show-practitioners.component.html',
  styleUrls: ['./show-practitioners.component.scss']
})
export class ShowPractitionersComponent {
  @Input() pracList: IPractitioner[];
  isProvider$ = this.dataService.isProvider$;

  constructor(private dataService: ServiceDataService) { }

  getLink(): string {
    let res = 'https://form.typeform.com/to/b7RiJ6K5';
    if (this.isProvider$.getValue()) {
      res = 'https://form.typeform.com/to/GPyr4mf2';
    }
    return res;
  }

}
