import { Component, OnInit } from '@angular/core';
import { IPractitioner } from '../hero-prac/practitioner.interface';
import { ServiceDataService } from '../data-service.service';

@Component({
  selector: 'app-practitioners',
  templateUrl: './practitioners.component.html',
  styleUrls: ['./practitioners.component.scss']
})
export class PractitionersComponent implements OnInit {
  practitioners: IPractitioner[];
  heroDataService: any;

  constructor(private service: ServiceDataService) { }

  ngOnInit(): void {
    this.loadPractitioners();
  }
  async loadPractitioners() {
    const aux = await this.service.getPractitioners();
    this.practitioners = aux.practitioners;
    this.heroDataService = aux.heroSection;
  }

}
