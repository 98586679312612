import { Component, Input } from '@angular/core';
import { ServiceDataService } from '../data-service.service';

@Component({
  selector: 'app-hero-prac',
  templateUrl: './hero-prac.component.html',
  styleUrls: ['./hero-prac.component.scss']
})
export class HeroPracComponent {
  @Input() heroData: any;
  isProvider$ = this.dataService.isProvider$;

  constructor(private dataService: ServiceDataService) { }

  getLink(): string {
    let res = 'https://form.typeform.com/to/b7RiJ6K5';
    if (this.isProvider$.getValue()) {
      res = 'https://form.typeform.com/to/GPyr4mf2';
    }
    return res;
  }

  toTypeform(): void {
    window.open(`${this.getLink()}`, '_blank');
  }
}
