import { Component, OnInit } from '@angular/core';
import { ServiceDataService } from '../data-service.service';
import { ShopifyService } from '../shopify.service';
@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {
  public shopData: any;

  constructor(private service: ServiceDataService) { }

  async ngOnInit() {
    this.shopData = await this.service.getShopping();

    setTimeout(function(){
      let service = new ShopifyService();
      service.loadBuyButton('shop-now');
      service.loadBuyButton('shop-now-bottom');
    }, 0);
  }

  shopNow(): string {
    return 'https://shop.healthstudiolabs.com';
  }

  takeTheQuiz(): string {
    return 'https://form.typeform.com/to/djWYiDmj';
  }
}
