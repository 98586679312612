import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hours'
})

export class HoursPipe implements PipeTransform {
  transform(value: any): string {
    let res = '';
    if (value === 1) {
      res = '1 hour';
    } else {
      res = `${value} hours`;
    }
    return res;
  }
}
