import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceDataService {
  private url = '../../assets/data';
  private type: any;

  public isProvider$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
  }

  async getLanding(pageType: string): Promise<any> {
    let provider = false;
    let res;
    if (pageType === 'providers') {
      provider = true;
      res = await this.http.get<{ landingData: any }>(`${this.url}/providers.json`).toPromise();
    } else {
      res = await this.http.get<{ landingData: any }>(`${this.url}/landing.json`).toPromise();
    }
    this.isProvider$.next(provider);
    return res.landingData;
  }

  async getPractitioners(): Promise<any> {
    const res = await this.http.get<{ practitionersData: any }>(`${this.url}/practitioners.json`).toPromise();
    return res.practitionersData;
  }

  async getShopping() {
    const res = await this.http.get<{ shopData: any }>(`${this.url}/shop.json`).toPromise();
    return res.shopData;
  }
}
