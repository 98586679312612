<div *ngIf="landingData" class="container">
  <p class="body-title"> {{ landingData.cards.title }}</p>
</div>
<app-show-cards *ngIf="landingData" [cardList]="landingData.cards.cardList"></app-show-cards>

<div *ngIf="landingData" class="container">
  <p class="body-title"> {{ landingData.firstAdvert.title }}</p>
</div>
<app-first-advert *ngIf="landingData" [firstAdvertData]="landingData.firstAdvert"></app-first-advert>

<div *ngIf="landingData" class="container">
  <p class="body-title"> {{ landingData.secondAdvert.title }}</p>
</div>
<app-second-advert *ngIf="landingData" [secondAdvertData]="landingData.secondAdvert"></app-second-advert>
