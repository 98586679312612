import { Component, OnInit } from '@angular/core';
import { ServiceDataService } from '../data-service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  icon = 'assets/img/icon_health_studio.svg';
  isProvider$ = this.dataService.isProvider$;

  constructor(private dataService: ServiceDataService) {
  }

  onClick(_: Event) {
    this.isProvider$.next(!this.isProvider$.getValue());
  }

  getLink(): string {
    let res = 'https://form.typeform.com/to/b7RiJ6K5';
    if (this.isProvider$.getValue()) {
      res = 'https://form.typeform.com/to/GPyr4mf2';
    }
    return res;
  }

  goToTypeform(): void {
    window.open(`${this.getLink()}`, '_blank');
  }

}
