<div *ngIf="heroData" [style.background-image]="'url(' + heroData.srcImage + ')'" class="container">
  <p class="hero-title">{{ heroData.heroTitle }}</p>
  <p class="hero-description">{{ heroData.heroDescription }}</p>
  <div *ngIf="(isProvider$ | async); else notProvider">
    <a target="_blank" [href]="getLink()" class="primary-button">{{ heroData.heroButton }}</a>
  </div>
  <ng-template #notProvider>
    <a [routerLink]="['/practitioners']" class="primary-button">{{ heroData.heroButton }}</a>
  </ng-template>
</div>
