import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price'
})

export class PricePipe implements PipeTransform {
  transform(value: any): string {
    return `$${value}/Hr`;
  }
}
