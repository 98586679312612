<ng-container *ngIf="shopData">
  <section class="hero-section container" [style.background-image]="'url(' + shopData.heroSection.srcImage + ')'">
    <h1 class="hero-title">{{ shopData.heroSection.title }}</h1>
    <h1 class="hero-subtitle">{{ shopData.heroSection.subTitle }}</h1>
    <div id="shop-now"></div>
    <a class="take-the-quiz" [href]="takeTheQuiz()" target="_blank">{{ shopData.heroSection.takeTheQuiz }}</a>
  </section>
  <div>
    <section class="features-section">
      <h2 class="body-title">{{ shopData.featuresSection.title }}</h2>
      <img [src]="shopData.featuresSection.srcImage" [alt]="shopData.featuresSection.altImage">
    </section>

    <section class="first-advert-section">
      <div class="section-wrapper">
        <img [src]="shopData.firstAdvert.srcImage" [alt]="shopData.firstAdvert.altImage">

        <div>
          <div>
            <h3>{{ shopData.firstAdvert.subTitle }}</h3>
            <p>{{ shopData.firstAdvert.firstParagraph }}</p>
            <p>{{ shopData.firstAdvert.secondParagraph }}</p>
            <p>{{ shopData.firstAdvert.thirdParagraph }}</p>
            <p>{{ shopData.firstAdvert.fourthParagraph }}</p>
          </div>
        </div>
      </div>
    </section>

    <section class="second-advert-section">
      <h2 class="body-title">{{ shopData.secondAdvert.title }}</h2>

      <div class="section-wrapper">
        <div>
          <h3>{{ shopData.secondAdvert.subTitle }}</h3>
          <p>{{ shopData.secondAdvert.firstParagraph }}</p>
          <p>{{ shopData.secondAdvert.secondParagraph }}</p>
        </div>

        <img [src]="shopData.secondAdvert.srcImage" [alt]="shopData.secondAdvert.altImage">
      </div>
    </section>

    <section class="third-advert-section">
      <h3 class="body-title">{{ shopData.thirdAdvert.title }}</h3>

      <div class="section-wrapper">
        <img [src]="shopData.thirdAdvert.srcImage" [alt]="shopData.thirdAdvert.altImage">

        <div>
          <p>{{ shopData.thirdAdvert.firstParagraph }}</p>
          <p class="firm-name">{{ shopData.thirdAdvert.firm }}</p>
        </div>
      </div>
    </section>

    <section class="reviews-section">
      <h3 class="body-title">{{ shopData.reviewsSection.title }}</h3>
      <div class="reviews">
        <div class="image-frame" *ngFor="let review of shopData.reviewsSection.reviews">
          <p><span class="image-text" *ngFor="let text of review.texts">{{text}}</span></p>
          <img [src]="shopData.reviewsSection.srcImage" [alt]="shopData.reviewsSection.altImage">
          <p class="image-text">{{review.author}}</p>
        </div>
      </div>
    </section>

    <section class="fourth-advert-section">
      <h3 class="body-title">{{ shopData.fourthAdvert.title }}</h3>

      <div class="help-frames">
        <div class="help-frame" *ngFor="let helpText of shopData.fourthAdvert.helpSections">
          <div class="image-frame"><img [src]="helpText.icon"></div>
          <p class="image-title">{{helpText.title}}</p>
          <p class="image-text">{{helpText.description}}</p>
        </div>
      </div>
    </section>

    <section class="get-started-section">
      <h3 class="body-title">{{ shopData.getStartedSection.title }}</h3>
      <div id="shop-now-bottom"></div>
      <a class="take-the-quiz" [href]="takeTheQuiz()" target="_blank">{{ shopData.getStartedSection.takeTheQuiz }}</a>
    </section>
  </div>

  <img class="logo" src="assets/img/icon_health_studio.svg">
</ng-container>
