<div *ngFor="let prac of pracList">
  <div class="container">
    <img [src]="prac.url">
    <div class="text-container">
      <div>
        <p class="practitioner-name">{{ prac.name }}</p>
        <p class="description">{{ prac.description }}</p>
        <p *ngIf="prac.address.streetAddress" class="description margin-button">{{ prac.address.streetAddress }},
          {{ prac.address.city }},
          {{ prac.address.state }}
          {{ prac.address.zipCode }}, {{ prac.address.country }}</p>
      </div>
      <div class="button-container view-button-primary">
        <a target="_blank" [href]="getLink()" class="primary-button btn">Book now</a>
      </div>
    </div>
  </div>
  <div class="products-container">
    <div *ngFor="let product of prac.products" class="products-table-row product">
      <div class="product-name">{{ product.name }}</div>
      <div class="product-price row-min">
        <a class="link" target="_blank" [href]="getLink()">{{ product.price }}</a>
      </div>
    </div>
  </div>
  <div class="button-container view-button-secondary">
    <a target="_blank" [href]="getLink()" class="primary-button btn">Book now</a>
  </div>
</div>
