import { Component, Input } from '@angular/core';
import { ICard } from './card.interface';

@Component({
  selector: 'app-show-cards',
  templateUrl: './show-cards.component.html',
  styleUrls: ['./show-cards.component.scss']
})
export class ShowCardsComponent {
  @Input() cardList: ICard[];

  constructor() { }

}
