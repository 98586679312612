import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LandingComponent } from './landing/landing.component';
import { HeroLandingComponent } from './hero-landing/hero-landing.component';
import { BodyLandingComponent } from './body-landing/body-landing.component';
import { ShowCardsComponent } from './show-cards/show-cards.component';
import { FirstAdvertComponent } from './first-advert/first-advert.component';
import { SecondAdvertComponent } from './second-advert/second-advert.component';
import { PractitionersComponent } from './practitioners/practitioners.component';
import { HeroPracComponent } from './hero-prac/hero-prac.component';
import { ShowPractitionersComponent } from './show-practitioners/show-practitioners.component';
import { HoursPipe } from './show-practitioners/time.pipe';
import { PricePipe } from './show-practitioners/price.pipe';
import { ServiceDataService } from './data-service.service';
import { HttpClientModule } from '@angular/common/http';
import { CanActivatePractitionersGuard } from './can-activate-prac.guard';
import { FooterComponent } from './footer/footer.component';
import { ShopComponent } from './shop/shop.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LandingComponent,
    HeroLandingComponent,
    BodyLandingComponent,
    ShowCardsComponent,
    FirstAdvertComponent,
    SecondAdvertComponent,
    PractitionersComponent,
    HeroPracComponent,
    ShowPractitionersComponent,
    HoursPipe,
    PricePipe,
    FooterComponent,
    ShopComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [
    ServiceDataService,
    CanActivatePractitionersGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
