
declare let ShopifyBuy: any;
declare global {
  interface Window {
      fbq:any;
  }
}

export class ShopifyService {
  uiClient() {
    let client = ShopifyBuy.buildClient({
      domain: 'health-studio.myshopify.com',
      storefrontAccessToken: '3714afb29fd3091624bc9d9f4596a16d',
    });

    return ShopifyBuy.UI.init(client);
  }

  loadBuyButton(id){
    let ui = this.uiClient();

    ui.createComponent('product', {
      id: '5891735650455',
      node: document.getElementById(id),
      moneyFormat: '%24%7B%7Bamount%7D%7D',
      options: {
        "product": {
          "DOMEvents": {
            'click .shopify-buy__btn ': function (evt, target) {
              var fbq = window.fbq;
              fbq('track', 'AddToCart', {}, { eventID: 'addToCart.' + evt.timeStamp });
            }
          },
          "styles": {
            "product": {
              "@media (min-width: 601px)": {
                "max-width": "calc(35% - 20px)",
                "margin-left": "20px",
                "margin-bottom": "50px"
              }
            },
            "title": {
              "font-family": "Cabin",
              "color": "#000000"
            },
            "button": {
              "font-family": "Cabin",
              "font-weight": "bold",
              "padding": "0.5rem 3rem",
              "margin-top": "30px",
              "font-size": "2.2em",
              ":hover": {
                "background-color": "#3764A5"
              },
              "background-color": "#3D6FB7",
              ":focus": {
                "background-color": "#3764A5"
              },
              "border-radius": "29px",
            },
            "quantityInput": {
              "font-size": "16px",
              "padding-top": "16px",
              "padding-bottom": "16px"
            },
            "price": {
              "font-family": "Cabin",
              "font-weight": "bold",
              "color": "#000000"
            },
            "compareAt": {
              "font-family": "Cabin",
              "font-weight": "bold",
              "color": "#000000"
            },
            "unitPrice": {
              "font-family": "Cabin",
              "font-weight": "bold",
              "color": "#000000"
            },
            "width": "300px",
          },
          "contents": {
            "img": false,
            "title": false,
            "price": false
          },
          "text": {
            "button": "Shop Now"
          },
          "googleFonts": [
            "Cabin"
          ]
        },
        "productSet": {
          "styles": {
            "products": {
              "@media (min-width: 601px)": {
                "margin-left": "-20px"
              }
            }
          }
        },
        "modalProduct": {
          "contents": {
            "img": false,
            "imgWithCarousel": true,
            "button": false,
            "buttonWithQuantity": true
          },
          "styles": {
            "product": {
              "@media (min-width: 601px)": {
                "max-width": "100%",
                "margin-left": "0px",
                "margin-bottom": "0px"
              }
            },
            "button": {
              "font-family": "Cabin",
              "font-weight": "bold",
              "font-size": "16px",
              "padding-top": "16px",
              "padding-bottom": "16px",
              ":hover": {
                "background-color": "#3764A5"
              },
              "background-color": "#3D6FB7",
              ":focus": {
                "background-color": "#3764A5"
              },
              "border-radius": "29px",
              "padding-left": "37px",
              "padding-right": "37px"
            },
            "quantityInput": {
              "font-size": "16px",
              "padding-top": "16px",
              "padding-bottom": "16px"
            }
          },
          "googleFonts": [
            "Cabin"
          ],
          "text": {
            "button": "Add to cart"
          }
        },
        "option": {},
        "cart": {
          "styles": {
            "button": {
              "font-family": "Cabin",
              "font-weight": "bold",
              "font-size": "16px",
              "padding-top": "16px",
              "padding-bottom": "16px",
              ":hover": {
                "background-color": "#3764A5"
              },
              "background-color": "#3D6FB7",
              ":focus": {
                "background-color": "#3764A5"
              },
              "border-radius": "29px"
            },
            "title": {
              "color": "#000000"
            },
            "header": {
              "color": "#000000"
            },
            "lineItems": {
              "color": "#000000"
            },
            "subtotalText": {
              "color": "#000000"
            },
            "subtotal": {
              "color": "#000000"
            },
            "notice": {
              "color": "#000000"
            },
            "currency": {
              "color": "#000000"
            },
            "close": {
              "color": "#000000",
              ":hover": {
                "color": "#000000"
              }
            },
            "empty": {
              "color": "#000000"
            },
            "noteDescription": {
              "color": "#000000"
            },
            "discountText": {
              "color": "#000000"
            },
            "discountIcon": {
              "fill": "#000000"
            },
            "discountAmount": {
              "color": "#000000"
            }
          },
          "text": {
            "total": "Subtotal",
            "button": "Checkout"
          },
          "googleFonts": [
            "Cabin"
          ]
        },
        "toggle": {
          "styles": {
            "toggle": {
              "font-family": "Cabin",
              "font-weight": "bold",
              "background-color": "#3D6FB7",
              ":hover": {
                "background-color": "#3764A5"
              },
              ":focus": {
                "background-color": "#3764A5"
              }
            },
            "count": {
              "font-size": "16px"
            }
          },
          "googleFonts": [
            "Cabin"
          ]
        },
        "lineItem": {
          "styles": {
            "variantTitle": {
              "color": "#000000"
            },
            "title": {
              "color": "#000000"
            },
            "price": {
              "color": "#000000"
            },
            "fullPrice": {
              "color": "#000000"
            },
            "discount": {
              "color": "#000000"
            },
            "discountIcon": {
              "fill": "#000000"
            },
            "quantity": {
              "color": "#000000"
            },
            "quantityIncrement": {
              "color": "#000000",
              "border-color": "#000000"
            },
            "quantityDecrement": {
              "color": "#000000",
              "border-color": "#000000"
            },
            "quantityInput": {
              "color": "#000000",
              "border-color": "#000000"
            }
          }
        }
      },
    });
  }
}
