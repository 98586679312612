import { Component, OnInit } from '@angular/core';
import { ServiceDataService } from '../data-service.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  heroDataService: any;
  landingDataService: any;
  constructor(private service: ServiceDataService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.loadLanding();
  }

  async loadLanding() {
    const type = this.route.snapshot.paramMap.get('type');
    const aux = await this.service.getLanding(type);
    const cards = aux.cards;
    const firstAdvert = aux.firstAdvert;
    const secondAdvert = aux.secondAdvert;
    this.heroDataService = aux.heroSection;
    this.landingDataService = { cards, firstAdvert, secondAdvert };
  }
}
