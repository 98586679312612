import { Component, Input } from '@angular/core';
import { ServiceDataService } from '../data-service.service';

@Component({
  selector: 'app-hero-landing',
  templateUrl: './hero-landing.component.html',
  styleUrls: ['./hero-landing.component.scss']
})
export class HeroLandingComponent {
  @Input() heroData: any;
  isProvider$ = this.dataService.isProvider$;
  url: string;

  constructor(private dataService: ServiceDataService) { }

  getLink(): string {
    let res = 'https://form.typeform.com/to/b7RiJ6K5';
    if (this.isProvider$.getValue()) {
      res = 'https://form.typeform.com/to/GPyr4mf2';
    }
    return res;
  }

}
