import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { PractitionersComponent } from './practitioners/practitioners.component';
import { CanActivatePractitionersGuard } from './can-activate-prac.guard';
import { ShopComponent } from './shop/shop.component';


const routes: Routes = [
  { path: '', component: ShopComponent },
  { path: 'platform', component: LandingComponent },
  { path: 'platform/:type', canActivate: [CanActivatePractitionersGuard], component: LandingComponent },
  { path: 'practitioners', component: PractitionersComponent },
  { path: 'welcome', component: ShopComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
