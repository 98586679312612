<section class="offer-banner" *ngIf="!(isProvider$ | async)">
  <p>Orders over $40 ship free. Orders over $75 are eligible for a 10 minute complimentary consultation with a licensed practicioner.</p>
</section>
<nav class="navbar-section">
  <a *ngIf="!(isProvider$ | async); else homeProviders" [routerLink]="['']" class="navbar-image"><img [src]="icon"></a>
  <ng-template #homeProviders>
    <a [routerLink]="['']" class="navbar-image"><img [src]="icon"></a>
  </ng-template>
  <ul class="nav-links">
    <li class="nav-link">
      <a *ngIf="!(isProvider$ | async); else notProvider" (click)="onClick($event)"
        [routerLink]="['/platform/providers']" class="link secondary-link-rounded">For providers</a>
      <ng-template #notProvider>
        <a (click)="onClick($event)" [routerLink]="['/platform']" class="link secondary-link-rounded">Clients</a>
      </ng-template>
    </li>
    <li class="nav-link view">
      <a target="_blank" href="https://blog.healthstudiolabs.com" class="link primary-link-rounded">Blog</a>
    </li>
    <li class="nav-link view">
      <a *ngIf="!(isProvider$ | async); else provider" [routerLink]="['/welcome']" class="link">Home</a>
      <ng-template #provider>
        <a [routerLink]="['/welcome']" class="link">Home</a>
      </ng-template>
    </li>
    <li class="nav-link view">
      <a [routerLink]="['/practitioners']" class="link">Discover</a>
    </li>
    <li class="nav-link view">
      <a target="_blank" [href]="getLink()" class="link">Register</a>
    </li>
    <li class="nav-link view">
      <a target="_blank" [href]="getLink()" class="link">Login</a>
    </li>
  </ul>
</nav>
