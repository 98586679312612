import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-body-landing',
  templateUrl: './body-landing.component.html',
  styleUrls: ['./body-landing.component.scss']
})
export class BodyLandingComponent {
  @Input() landingData: any;

  constructor() { }

}
