import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, ActivatedRoute, Router } from '@angular/router';
import { ServiceDataService } from './data-service.service';

@Injectable({
  providedIn: 'root'
})
export class CanActivatePractitionersGuard implements CanActivate {
  constructor(public dataService: ServiceDataService, private route: ActivatedRoute, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // if (!this.dataService.isProvider$.getValue()) {
    if (route.params.type !== 'providers') {
      this.router.navigate(['']);
      return false;
    }
    return true;
  }
}
