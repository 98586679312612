import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-first-advert',
  templateUrl: './first-advert.component.html',
  styleUrls: ['./first-advert.component.scss']
})
export class FirstAdvertComponent {
  @Input() firstAdvertData: any;

  constructor() { }

}
