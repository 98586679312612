import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-second-advert',
  templateUrl: './second-advert.component.html',
  styleUrls: ['./second-advert.component.scss']
})
export class SecondAdvertComponent {
  @Input() secondAdvertData: any;

  constructor() { }

}
