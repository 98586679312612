<nav class="footer-section">
  <div class="footer-texts">
    <p>Need Help?</p>
    <p>Drop us a line: <a href="mailto=info@healthstudiolabs.com">info@healthstudiolabs.com</a></p>
    <p class="terms">
      <a href="https://shop.healthstudiolabs.com/policies/privacy-policy">Privacy Policy</a>
      <a href="https://shop.healthstudiolabs.com/policies/terms-of-service">Terms of Service</a>
    </p>
    <p class="copyright">2021 Health Studio. All rights reserved.</p>
  </div>
  <div class="footer-links">
    <ul>
      <li class="footer-link">
        <a *ngIf="!(isProvider$ | async); else notProvider" (click)="onClick($event)"
          [routerLink]="['/platform/providers']" class="link secondary-link-rounded">For providers</a>
        <ng-template #notProvider>
          <a (click)="onClick($event)" [routerLink]="['/platform']" class="link secondary-link-rounded">Clients</a>
        </ng-template>
      </li>
      <li class="footer-link view">
        <a target="_blank" href="https://blog.healthstudiolabs.com" class="link primary-link-rounded">Blog</a>
      </li>
      <li class="footer-link view">
        <a *ngIf="!(isProvider$ | async); else provider" [routerLink]="['/welcome']" class="link">Home</a>
        <ng-template #provider>
          <a [routerLink]="['/welcome']" class="link">Home</a>
        </ng-template>
      </li>
      <li class="footer-link view">
        <a [routerLink]="['/practitioners']" class="link">Discover</a>
      </li>
      <li class="footer-link view">
        <a target="_blank" [href]="getLink()" class="link">Register</a>
      </li>
      <li class="footer-link view">
        <a target="_blank" [href]="getLink()" class="link">Login</a>
      </li>
    </ul>
    <div class="footer-disclaimers">
      <p>* These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure or prevent any disease </p>
      <p>USP has tested and verified ingredients, potency, and manufacturing process. USP sets official standards for dietary supplements. See www.uspverified.org.</p>
      <p>The FDA plays a specific role in regulating the nutraceutical industry. Learn more.</p>
    </div>
  </div>
</nav>
